import "../../App.css"
import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../images/logo.jpg'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container, Row } from "react-bootstrap";

const Navitem = () => {
    return (
        <div className="main-header">
           <Navbar expand="lg" className="bg-body-tertiary1" >
                <Container fluid>
                    <Navbar.Brand href="/" className="company-logo"><img src={Logo} alt="" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-toggle" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto list-items">
                            <NavLink to="/" className="list">Home</NavLink>
                            <NavLink to="/Setup" className="list">Setup</NavLink>
                            <NavLink to="/Pour" className="list">Pour</NavLink>
                            <NavLink to="/Base" className="list">Base</NavLink>
                            <NavLink to="/Rectification" className="list">Rectification</NavLink>
                        </Nav>
                        <Nav className="social-links">
                            <ul>
                                <li><a href="" target="_blank">Facebook</a></li>
                                <li><a href="" target="_blank">Instagram</a></li>
                                <li><a href="" target="_blank">Linkedin</a></li>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navitem