
import './App.css';
import { Route, Routes } from 'react-router-dom'
import SetUp from './pages/setup/SetUp';
import Pour from './pages/pour/Pour';
import Base from './pages/base/Base';
import Rectification from './pages/rectification/Rectification';
import Home from './pages/home/Home';

function App() {
  return (
    <div className="App">

      {/* Defining routes path and rendering components as element */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/SetUp" element={<SetUp />} />
        <Route path="/Pour" element={<Pour />} />
        <Route path="/Base" element={<Base />} />
        <Route path="/Rectification" element={<Rectification />} />
      </Routes>
    </div>
  );
}

export default App;
