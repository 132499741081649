import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";


const PrintPdf = (props) => {

    const { fileName } = props
    const currentDate = new Date().toLocaleString();
    // const targetDate = '09/10/2023';

    // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    // const dateCompare = currentDate <= targetDate
    
    // useEffect(() => {
    //     if (dateCompare) {
    //         setIsButtonDisabled(false)
    //         console.log('Server Connected!')
    //     } else {
    //         setIsButtonDisabled(true)
    //         console.log('Button is disabled due to server side error!')
    //     }
    // }, [])

    const handleGeneratePdf = async () => {
        const divToPrint = document.querySelector('#element-to-print');

        html2canvas(divToPrint).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 315;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF('p', 'mm');

            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = doc.internal.pageSize.getHeight();

            let position = 0;
            doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
            heightLeft -= pageHeight;
            // doc.text(130, 10, "Rock N Hill Constructions");


            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
                // doc.text(130, 10, "Rock N Hill Constructions");
            }

            doc.save(`${fileName}_${currentDate}`);
            // setLoader(false);
        });
    };


    return (
        <div>
            <div className='setup-link'>
                <Container className="row-container mt-5" fluid>
                    <Row justify="center" style={{ marginBottom: "10px" }}>
                        <Col lg={2}>
                            <button 
                            className="button-generate" 
                            onClick={handleGeneratePdf} 
                            // disabled={isButtonDisabled} 
                            >
                                Generate PDF
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PrintPdf