import React, { useRef } from 'react'
import Nav from '../nav/Navitem';
import ImageData from '../common/ImageData';
import PrintPdf from '../common/PrintPdf';

const Rectification = () => {
  const reportTemplateRef = useRef(null);
  return (
    <div>
      <Nav />
      <div ref={reportTemplateRef} id='element-to-print'>
        <ImageData name="Rectification" data={"1. Take photo of builder sign board"} limit={"1(Max)"} max={1} />
        <ImageData data={"2. Take Photos of frame overhang with tape measure"} limit={"8(Max)"} max={8} />
        <ImageData data={"3. Take photos of concrete overhang"} limit={"8(Max)"} max={8} />
        <ImageData data={"4. Take Photos of after rectification"} limit={"8(Max)"} max={8} />
      </div>
      <PrintPdf fileName={'rectification'} />

    </div>
  )
}

export default Rectification