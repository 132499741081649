import React, { useRef } from 'react'
import Nav from '../nav/Navitem';
import ImageData from '../common/ImageData';
import PrintPdf from '../common/PrintPdf';


const SetUp = () => {

  const reportTemplateRef = useRef(null);

  return (
    <div className='setup-link'>
      <Nav />
      <div ref={reportTemplateRef} id='element-to-print'>
        <ImageData name="Set Up" data={"1. Entry of site"} limit={"2(Max)"} max={2} />
        <ImageData data={"2. Take Photo Of Boxing All Around"} limit={"4(Max)"} max={4} />
        <ImageData data={"3. Take photo of Earth Bar Installed"} limit={"4(Max)"} max={4} />
        <ImageData data={"4. Take Photo Of Rubbish Bagger Up and Secured Infront"} limit={"4(Max)"} max={4} />
        <ImageData data={"5. Take Photo Of All Corners Of Job"} limit={"3(Max)"} max={3} />
        <ImageData data={"6. Take Photo Of Builder's Sign"} limit={"1(Max)"} max={1} />
        <ImageData data={"7. Take Photo Of Fence Closed"} limit={"3(Max)"} max={3} />
      </div>

      <PrintPdf fileName={'setup'} />

    </div>
  )
}

export default SetUp