import React, { useRef } from 'react'
import Nav from '../nav/Navitem'
import ImageData from '../common/ImageData'
import PrintPdf from '../common/PrintPdf';



const Pour = () => {
    const reportTemplateRef = useRef(null);

    return (
        <div className='setup-link'>
            <Nav />
            <div ref={reportTemplateRef} id='element-to-print'>
                <ImageData name="Pour" data={"1. Entry Of Site Photos"} limit={"2(Max)"} max={2} />
                <ImageData data={"2. Take photos of each walling showing all rebates covered with poly"} limit={"2(Max)"} max={2} />
                <ImageData data={"3. Take photos from all corners of JOB"} limit={"2(Max)"} max={2} />
                <ImageData data={"4. Take photo of ROD Rubbish placed infront and secured"} limit={"2(Max)"} max={2} />
                <ImageData data={"5. Take photo of builders sign"} limit={"2(Max)"} max={2} />
                <ImageData data={"6. Take photo of driveway and road cleaned with fences closed"} limit={"2(Max)"} max={2} />
            </div>
            
            <PrintPdf fileName={'pour'}/>
        </div>
    )
}

export default Pour