import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import useFileSelection from '../../hooks/useFileSelection';
import UploadButton from "../../components/UploadButton";


const ImageData = (props) => {
    const [addFile, removeFile, base64Strings, isLoading] = useFileSelection();
    const { name, data, limit, max } = props

    
    return (
        <div className='main-content'>
            <Container fluid className='main-container'>
                <Row>
                    <Col span={12} lg={12} className="siteHeading">
                        <h2>{name}</h2>
                        <p>{data} - {limit}</p>
                    </Col>
                </Row>
                <Row>
                    {base64Strings.map((base64String, index) => (
                        <Col lg={3} xs={4} className="mb-1">
                            <img
                                src={base64String}
                                key={index}
                                alt=""
                                // style={{ width: "150px", height: "200px" }}
                                className="image-from-source"
                            />
                        </Col>
                    ))}
                </Row>

                <Row justify="center" style={{ marginBottom: "10px" }}>
                    <Col lg={2} xs={6}>
                        {base64Strings.length === max ? false : true &&
                            <UploadButton
                                addFile={addFile}
                                removeFile={removeFile}
                                loading={isLoading}
                            />
                        }
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default ImageData