import React, { useRef } from 'react'
import Nav from '../nav/Navitem';
import ImageData from '../common/ImageData';
import PrintPdf from '../common/PrintPdf';

const Base = () => {
  const reportTemplateRef = useRef(null);
  return (
    <div>
      <Nav />
      <div ref={reportTemplateRef} id='element-to-print'>
        <ImageData name="Base" data={"1. Take photos upon entry of site"} limit={"4(Max)"} max={4} />
        <ImageData data={"2. Take Photos of the base"} limit={"3(Max)"} max={3} />
        <ImageData data={"3. Take photos of the edge beans if nay"} limit={"4(Max)"} max={4} />
        <ImageData data={"4. Take Photos of spoil if moved to any neighbouring property"} limit={"2(Max)"} max={2} />
        <ImageData data={"5. Take Photos of crossover, drive way and road cleaned"} limit={"4(Max)"} max={4} />
        <ImageData data={"6. Take Photo Of Builder's Sign"} limit={"1(Max)"} max={1} />
      </div>
      <PrintPdf fileName={'base'} />

    </div>
  )
}

export default Base